@import '~antd/dist/antd.css';

#root,
.main {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.layout-background {
  background: #fff;
  overflow-y: scroll;
}

.header {
  padding: 0 20px;
}

.logo {
  width: 120px;
}

.logo img {
  width: 100%;
}

.site-statistic-demo-card {
  background: #ececec;
  padding: 30px;
}

.stat-card {
  border: 1px solid #cccccc;
  margin: 2px;
}

/* .layout { */
/* background-color:#ffff; */
/* } */

.chart-container {
  height: 80%;
  margin-top: 20px;
}
.month-picker {
  margin-left: 50px;
  margin-bottom: 0px;
}

.clickable {
  cursor: pointer;
}
